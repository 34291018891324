<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
    <submenu-partner menu="data"></submenu-partner> 
      
      <div class="submenu-content">

        <img src="/media/images/on-demand-banner.png" class="img-fluid">

        <p class="my-5">
          <span class="d-table fw-bold" style="font-size: 30px">Ma Labs On-Demand Webinars</span>
          Gain access to on-demand webinar recordings from Ma Labs' top vendors with just a few clicks.
        </p>

        <div id="webinars" class="row">
          <div class="col-lg-6 mb-5 pe-xxl-5">
            <div class="vid-container"> 
              <iframe allow="fullscreen" class="responsive-iframe" src="https://www.youtube.com/embed/UG8wiuUi5yM?si=VM4L31Mpce-z-aHP"></iframe>
            </div>

            <h1 class="mt-4 mb-3">Samsung Webinar</h1>
            <h2 class="fw-bold">Discover Samsung's array of B2B SSD products</h2>
            <span class="font-italics">Live Session Recorded on May 15, 2024</span>
            <p class="mt-4 mb-5">
              Delve into Samsung's comprehensive B2B SSD product portfolio, detailed roadmap, and the latest groundbreaking innovations.
            </p>
          </div>

          <div class="col-lg-6 mb-5 ps-xxl-5">
            <div class="vid-container"> 
              <iframe allow="fullscreen" class="responsive-iframe" src="https://www.youtube.com/embed/CsxA1hxgAm0?si=nL6D7MYY2FzlsmG4"></iframe>
            </div>

            <h1 class="mt-4 mb-3">Supermicro Webinar</h1>
            <h2 class="fw-bold">Revolutionizing AI Technology</h2>
            <span class="font-italics">Live Session Recorded on May 15, 2024</span>
            <p class="mt-4 mb-5">
              Discover Supermicro's advanced IT solutions tailored for Enterprise, Cloud, AI, Metaverse, and 5G Telco/Edge infrastructure.
            </p>
          </div>

          <div class="col-lg-6 mb-5 pe-xxl-5">
            <div class="vid-container"> 
              <iframe allow="fullscreen" class="responsive-iframe" src="https://www.youtube.com/embed/BR-sOB40kN0?si=MrybQnXtxP1RdwDS"></iframe>
            </div>

            <h1 class="mt-4 mb-3">Kingston Webinar</h1>
            <h2 class="fw-bold">Advancing Memory Solutions and Chipset Architecture offerings</h2>
            <span class="font-italics">Live Session Recorded on May 16, 2024</span>
            <p class="mt-4 mb-5">
              Explore Kingston's Memory Solutions and Chipset Architecture offerings.
            </p>
          </div>

          <div class="col-lg-6 mb-5 ps-xxl-5">
            <div class="vid-container"> 
              <iframe allow="fullscreen" class="responsive-iframe" src="https://www.youtube.com/embed/yIx7NBLL2LE?si=_-05dYYGwCVsW4VS"></iframe>
            </div>
            <h1 class="mt-4 mb-3">ASUS Webinar</h1>
            <h2 class="fw-bold">Empowering Digital Transformation with ASUS Together</h2>
            <span class="font-italics">Live Session Recorded on May 16, 2024</span>
            <p class="mt-4 mb-5">
              Discover ASUS's range of motherboard, mini PC, and VGA products.
            </p>
          </div>
        </div>

      </div> 
    
  </div>
</template>

<script>
import submenuPartner from '../../components/nav/submenuPartner.vue'
import BreadcrumbPage from "../../components/BreadcrumbPage.vue"
import { ref } from "vue"
import { getAPI } from '../../utils/axios-api'
import { BackendDomain } from "../../utils/settings.js";

export default {
  name: 'Webinars',
  components: { submenuPartner, BreadcrumbPage },
  setup() {
    const error = ref(null)

    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "Partners", link: ""})
    breadcrumb_links.value.push({text: "Ma Labs On-Demand Webinars", link: "Webinars"})

    

    return { error, breadcrumb_links,BackendDomain}

  }
}
</script>

<style scoped>
  #webinars {
    max-width: 1335px;
  }
  #webinars div {
    max-width: 560px;
  }
  #webinars .vid-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
  }
  #webinars iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
  #webinars h1 {
    color: #0B4971;
    font-size: 30px;
    font-weight: bold;
    text-align: left;
    text-transform: none;
  }
  #webinars h2 {
    font-size: 20px;
  }
  #webinars span {
    font-style: italic;
  }
</style>